import logo from './logo.svg';
import './App.css';
import U from './assets/u.jpg'
import Logo from './assets/logo — копия 3.jpg'
import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
const App = () => {
  
  const form = useRef();
  const [name, setName] = useState(null)
  const [email, setEmail] = useState(null)
  const [message, setMessege] = useState(null)
  const sendEmail = (e) => {
    e.preventDefault();

    const serviceId = 'service_euf9b9m'
    const templateId = 'contact_form'
    const punlicKey = '-_Ap9uu91e1RkpZHh'

    const templateParams = {
      from_name:name,
      from_email:email,
      to_name:"human_recovery",
      message:message
    }
    emailjs.send(serviceId, templateId, templateParams,punlicKey)
      .then((response) => {
          console.log('SUCCESS!', response);
          setName('');
          setEmail('');
          setMessege('');
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
  };
  return (
    <div className="">
      <div className='flex justify-center items-center h-screen gap-12 mobile:flex-wrap'>
        <div className=' flex justify-center items-center max-w-[500px] w-full h-[500px] bg-gray-200'>
          <img className='max-w-[400px] w-full h-auto' src={U}/>
        </div>
         <div className='flex flex-col items-center gap-4'>
            <img className='w-56 h-auto' src={Logo} />
            <div className='flex flex-col gap-4 max-w-[400px] w-full'>
              <p className='text-5xl text-blue-400'>Наша поддержка</p>
              <p className='text-2xl'>Офицальная поддержка <br/> Human-recovery</p>
              <p>Наша служба поддержки делает все возможное, чтобы обрабатывать Ваши обращения в кратчайшие сроки. Как только мы ответим на Ваш запрос (тикет), Вам придет уведомление на Вашу электронную почту</p>
            </div>
            <div className='flex flex-col justify-start w-full mobile:mb-12'>
              <form className='flex flex-col gap-6 border border-gray-400 rounded-lg p-4' ref={form} onSubmit={sendEmail}>
                <span className='flex gap-4 border border-blue-300 p-2 rounded-lg'>
                  <label>Имя</label>
                  <input className='w-full px-3 border border-gray-300 rounded-xl focus:outline-none focus:border-blue-300' type="text" name="user_name" value={name} onChange={(e) => setName(e.target.value)} />
                </span>
                <span className='flex gap-4 border border-blue-300 p-2 rounded-lg'>
                  <label>Email</label>
                  <input className='w-full px-3 border border-gray-300 rounded-xl focus:outline-none focus:border-blue-300' type="email" name="user_email" value={email} onChange={(e) => setEmail(e.target.value) }/>
                </span>
                <span className='flex flex-col items-center gap-4 border border-blue-300 p-2 rounded-lg'>
                  <label >Сообщение</label>
                  <textarea className='w-full px-3 py-1 border border-gray-300 rounded-xl focus:outline-none focus:border-blue-300' name="message" value = {message} onChange={(e) => setMessege(e.target.value)} />
                </span>
                <input className='border border-blue-300 rounded-lg h-12 cursor-pointer' type="submit" value="Отправить"  />
              </form>
            </div>
        </div>
      </div>
    </div>
  );
}

export default App;


// Sanamed@list.ru